import { useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    Stack,
} from '@mui/material';



export default function ProfileDetails() {
    const user = useSelector((state) => state.auth.user);
    const managerInfo = user?.manager;

    return (
        <Stack spacing={3}>
            <Card>
                <CardHeader
                    title="My Profile"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Username
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {user?.username || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Email Address
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {user?.email || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Employee Id
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {user?.employee_id || '-'}
                                </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Designation
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {user?.designation || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Department
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {user?.department || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Region
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {user?.region || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>

            {managerInfo && <Card>
                <CardHeader
                    title="Manager Info"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Name
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {managerInfo?.username || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Email Address
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {managerInfo?.email || '-'}
                                </Typography>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Employee Id
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {managerInfo?.employee_id || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Designation
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {managerInfo?.designation || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Department
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {managerInfo?.department || '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                            >
                                <Typography
                                    color="text.secondary"
                                    variant="body2"
                                >
                                    Region
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="title"
                                >
                                    {managerInfo?.region || '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>}
        </Stack>
    );
};
