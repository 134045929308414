import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Unstable_Grid2 as Grid,
    Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { validatePassword } from '../../utils';
import makeApiRequest from '../../Redux/api';
import { setErrorMessage, setLoading, setSuccessMessage } from '../../Redux/slices/uiSlice';
import { logout } from '../../Redux/slices/authSlice';
import ErrorHelperText from '../Common/ErrorHelperText';

export default function ChangePassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = useState({
        currentPassword: '',
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState({
        password: false,
        confirm: false,
        currentPassword: false
    });

    const handleChange = useCallback(
        (event) => {
            setErrors((prevState) => ({
                ...prevState,
                [event.target.name]: false
            }));
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        []
    );

    const handleSubmit = () => {
        let isPasswordMatch = true
        let isCurrentPassword = true
        const isPasswordValid = validatePassword(values?.password);

        if (values?.password !== values?.confirm) {
            isPasswordMatch = false
        }

        if (!values?.currentPassword) {
            isCurrentPassword = false
        }

        setErrors({
            password: !isPasswordValid,
            currentPassword: !isCurrentPassword,
            confirm: !isPasswordMatch,
        });

        if (isPasswordValid && isPasswordMatch && isCurrentPassword) {
            const data = {
                currentPassword: values?.currentPassword,
                password: values?.password,
                passwordConfirmation: values?.confirm,
            };
            dispatch(setLoading(true));
            makeApiRequest(`/api/auth/change-password`, 'POST', data)
                .then(resp => {
                    if (resp?.status === 200) {
                        dispatch(setSuccessMessage('Password changed successfully'));
                        setTimeout(() => {
                            dispatch(logout());
                            navigate('/');
                        }, 3500);

                    }
                })
                .catch((error) => {
                    const respData = error?.response?.data;
                    let errorMsg = 'Something went wrong. Try again later';
                    if(respData?.error?.message){
                        errorMsg = respData?.error?.message;
                    }
                    dispatch(setErrorMessage(errorMsg));
                    console.error('change password API Error:', error);
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
    };

    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                xs={12}
                md={6}
            >
                <Card>
                    <CardHeader
                        title="Change Password"
                        action={
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Tooltip title="Hide Password"><VisibilityOff /></Tooltip> : <Tooltip title="Show Password"><Visibility /></Tooltip>}
                            </IconButton>
                        }
                    />
                    <CardContent sx={{ py: 1 }}>
                        <Stack spacing={3}>
                            <TextField
                                fullWidth
                                label="Current Password"
                                name="currentPassword"
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.currentPassword}
                                helperText={
                                    errors.currentPassword && <ErrorHelperText error='Please Enter Current Password'/>
                                }
                                autoComplete="off"
                            />
                            <TextField
                                fullWidth
                                label="Password"
                                name="password"
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                helperText={
                                    errors.password && <ErrorHelperText error='Password should have at least 8 characters, a capital letter, a number, and a special symbol' />
                                }
                                autoComplete="off"
                            />
                            <TextField
                                fullWidth
                                label="Password (Confirm)"
                                name="confirm"
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.confirm}
                                helperText={
                                    errors.confirm && <ErrorHelperText error='Confirm password is not same as password'/>
                                }
                                autoComplete="off"
                            />
                        </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Button variant="contained" sx={{ mr: 2 }} onClick={handleSubmit}>
                            Update
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};
