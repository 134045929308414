import React from 'react'
import { Box, CircularProgress } from '@mui/material'

export default function Loader() {
    return (
        <Box component="div" display="flex" alignItems='center' justifyContent="center" style={{ width: '100%', paddingTop: 35 }}>
            <CircularProgress color='primary' />
        </Box>
    )
}
