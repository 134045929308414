import React, { useState } from 'react';
import { Box, Table, TableHead, TableRow, TableBody, TableContainer, TableCell, Grid, Card, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ScrollBar } from '../Common/ScrollBar';

const HierarchyTable = (props) => {
    const { managerData, reporteesData, user } = props;
    const [expandedRows, setExpandedRows] = useState([]);

    const handleToggle = (id) => {
        setExpandedRows((prevRows) =>
            prevRows.includes(id)
                ? prevRows.filter((rowId) => rowId !== id)
                : [...prevRows, id]
        );
    };

    const renderTable = (items, level = 1) => {
        return items.map((item) => {
            return (
                <React.Fragment key={`HT-L-${level}-${item.id}`}>
                    <TableRow hover sx={item?.id == user?.id ? { backgroundColor: '#e3f2fd' } : {}}>
                        <TableCell sx={{ paddingLeft: level * 2 }}>
                            <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
                                {item?.children?.length > 0 && (
                                    <Box onClick={() => handleToggle(item.id)} sx={{ cursor: 'pointer' }}>
                                        {expandedRows.includes(item.id) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                    </Box>
                                )}
                                <Box component="span" sx={{ paddingLeft: 1 }}>
                                    <Typography variant='title'>
                                        {item?.username} {`${item?.id == user?.id?'(you)':''}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            {item.children && !expandedRows.includes(item.id)
                                ?
                                <Box display="flex" flexDirection="column">
                                    {item?.children?.length > 0 && <Typography variant='subtitle2'>
                                        Team Salary
                                    </Typography>}
                                    <Typography>
                                        {calculateTotalSalary(item)}
                                    </Typography>
                                </Box>
                                : item.employee_salary?.yearly_salary || 0}
                        </TableCell>

                        <TableCell>{item?.department || '-'}</TableCell>
                        <TableCell>{item?.region || '-'}</TableCell>
                        <TableCell>{item?.employee_salary?.start_date || '-'}</TableCell>
                    </TableRow>
                    {expandedRows.includes(item.id) && item.children && renderTable(item.children, level + 1)}
                </React.Fragment>
            )
        });
    };

    const createHierarchy = (managerData, reporteesData) => {
        const managerWithChildren = {
            ...managerData,
            children: reporteesData
        };
        return [managerWithChildren];
    };

    const calculateTotalSalary = (item) => {
        const ownSalary = item.employee_salary?.yearly_salary || 0;
        const childrenSalary = item.children
            ? item.children.reduce((total, child) => total + calculateTotalSalary(child), 0)
            : 0;
        return ownSalary + childrenSalary;
    };

    const hierarchy = createHierarchy(managerData, reporteesData);

    return (
        <Card sx={{ height: '100%' }} >
            <ScrollBar sx={{ flexGrow: 1 }}>
                <Box sx={{}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Total Salary</TableCell>
                                    <TableCell>Department</TableCell>
                                    <TableCell>Region</TableCell>
                                    <TableCell>Start Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderTable(hierarchy)}</TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </ScrollBar>
        </Card>
    );
};

export default HierarchyTable;
