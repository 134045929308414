import React, { useCallback, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid,
    Container,
    FormControl, InputLabel, Select, MenuItem,
    Typography,
    Stack,
    useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import InputAccordion from './InputAccordion';
import MUIAutocomplete from './Common/MUIAutoComplete';
import { useParams, useNavigate } from 'react-router-dom';
import { STATUS_OPTIONS } from '../utils/constants';
import makeApiRequest from '../Redux/api';
import { setErrorMessage, setLoading, setSuccessMessage } from '../Redux/slices/uiSlice';
import { validateNumberField } from '../utils';
import ErrorHelperText from './Common/ErrorHelperText';
import MUIModal from './Common/MUIModal';

function findBonusPartsChanges(bonusParts, userBonusData) {
    const changes = {
        added: [],
        modified: [],
        deleted: [],
        addedOrModified:[]
    };

    const userBonusDataMap = new Map(userBonusData.map(item => [item.id, item]));

    bonusParts.forEach(bonus => {
        const original = userBonusDataMap.get(bonus.id);
        
        if (!original) {
            changes.deleted.push({ ...bonus, type: 'deleted' });
        } else {
            userBonusDataMap.delete(bonus.id);
            if (JSON.stringify(bonus) !== JSON.stringify(original)) {
                if (bonus.status === 'Added' || bonus.status === 'Updated' || bonus.status === 'Disagreed' || bonus.status === 'Rejected' || bonus.status === 'Agreed') {
                    original.status = 'Updated';
                }
                changes.modified.push({ ...bonus,
                    ...original,
                    type: 'modified' 
                });
            }
        }
    });

    changes.added = Array.from(userBonusDataMap.values()).map(item => ({ ...item,status:'Added', type: 'added' }));
    changes.addedOrModified = [...changes.added, ...changes.modified];
    return changes;
}


function CreateUserBonus({managerId}) {
    const theme = useTheme();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [period, setPeriod] = useState(null);
    const [values, setValues] = useState({
        bonusMax: 0,
        bonusMaxPercentage: 0,
        status: 'Todo',
    });
    const [bonusParts, setBonusParts] = useState([]);
    const [accordionCount, setAccordionCount] = useState(0);
    const [refreshCount, setRefreshCount] = useState(0);
    const [userBonusData, setBonusData] = useState([]);
    const [errors, setErrors] = useState({
        user: '',
        period: '',
        bonusMax: '',
        bonusMaxPercentage: '',
        bonusParts: bonusParts.map(() => ({}))
    });
    const [triggerValidate, setTriggerValidate] = useState(false);
    const [accordionValid, setAccordionValid] = useState(true);
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        if (!id) return;
        makeApiRequest(`/api/user-bonuses/${id}`).then(resp => {
            if (resp?.status === 200) {
                const respData = resp?.data?.data;
                const valueObj = {
                    bonusMax: respData?.Bonus_Max || 0,
                    bonusMaxPercentage: respData?.Bonus_Max_Percentage || 0,
                    bonusOffered: respData?.Bonus_Offered,
                    bonusPercentage: respData?.Bonus_Percentage,
                    status: respData?.Status,
                    createdAt: respData?.createdAt,
                    updatedAt: respData?.updatedAt,
                    publishedAt: respData?.publishedAt,
                    created_by: respData?.created_by,
                    updated_by: respData?.updated_by,
                }

                const bParts = respData?.Bonus_Parts.map(item => {
                    return ({
                        id: item?.id,
                        title: item?.Display_Text,
                        Weightage: item?.Weightage || 0,
                        KPI_Bonus: item?.KPI_Bonus ? {
                            id: item?.KPI_Bonus?.id,
                            label: item?.KPI_Bonus?.Name
                        } : null,
                        status: item?.status,
                        jsonData: item?.jsonData
                    })
                });
                setUser({ id: respData?.User?.id, label: respData?.User?.username, data: { employee_id: respData?.User?.employee_id } });
                setPeriod({ id: respData?.Period?.id, label: respData?.Period?.Name });
                setValues(valueObj);
                setBonusParts(bParts);
                setBonusData(bParts);
            }
        })

    }, [id, refreshCount]);


    const validateForm = () => {
        let formIsValid = true;
        let err = { ...errors };
        if (!user) {
            formIsValid = false;
            err.user = 'This field is required';
        }
        if (!period) {
            formIsValid = false;
            err.period = 'This field is required';
        }
        if (values?.bonusMax === null || values?.bonusMax === undefined || values?.bonusMax === '') {
            formIsValid = false;
            err.bonusMax = 'This field is required';
        } else if (Number(values?.bonusMax) <= 0) {
            formIsValid = false;
            err.bonusMax = 'Value must be greater than 0';
        }

        if (values?.bonusMaxPercentage === null || values?.bonusMaxPercentage === undefined || values?.bonusMaxPercentage === '') {
            formIsValid = false;
            err.bonusMaxPercentage = 'This field is required';
        } else if (Number(values?.bonusMaxPercentage) <= 0) {
            formIsValid = false;
            err.bonusMaxPercentage = 'Value must be greater than 0';
        } else if (Number(values?.bonusMaxPercentage) > 100) {
            formIsValid = false;
            err.bonusMaxPercentage = 'Value must be less than 100';
        }

        if (errors.bonusParts.some(errorObj => Object.keys(errorObj ? errorObj : {}).length > 0)) {
            formIsValid = false;
        }

        setErrors(err);
        return {
            err,
            formIsValid
        };
    }

    const validateAccordion = () => {
        setTriggerValidate(true);
        const isValid = bonusParts.every(entry => (entry.Weightage && entry.KPI_Bonus));
        setAccordionValid(isValid);
        return isValid;
    };

    const handleSubmit = () => {
        setTriggerValidate(true);

        const { formIsValid } = validateForm();
        if (!formIsValid) {
            dispatch(setErrorMessage('To proceed further, please fill the mandatory fields'));
            return;
        }

        if (bonusParts.length > 0) {
            const isValidAccordion = validateAccordion();
            if (!isValidAccordion) {
                dispatch(setErrorMessage('Please fill all required fields in the bonus parts.'));
                return;
            }
        }

        const changes = findBonusPartsChanges(userBonusData, bonusParts);
        let filterRemovedParts = bonusParts;
        
        if(changes.deleted.length > 0){
            filterRemovedParts = bonusParts.filter(item => changes.deleted.find(fItem =>fItem.id !== item.id))
        }
        const updatedParts = filterRemovedParts.map(item => {
            const matchedItem = changes.addedOrModified.find(matchItem => matchItem.id === item.id);
            if (matchedItem) {
                return{
                    ...item,
                    ...matchedItem
                }
            }
            // if (item?.KPI_Bonus?.data) {
            //     delete item.id
            //     delete item.title;
            //     delete item.KPI_Bonus.data;
            // }
            return item;
        });


        const finalParts = updatedParts.map(item=>{
            if(item.type == 'added'){
                delete item.id
                delete item.title;
                delete item.KPI_Bonus.data;
            }
            if(item.type){
                delete item.type;
            }
            return item;
        })

        const data = {
            Status: values?.status,
            User: {
                connect: [
                    {
                        id: user?.id,
                        // username: user?.label
                    }
                ]
            },
            Period: {
                connect: [
                    {
                        id: period?.id,
                        // Name: period?.label 
                    }
                ]
            },
            Bonus_Parts: finalParts.map(item => {
                return {
                    ...item,
                    Weightage: parseFloat(item.Weightage),
                    KPI_Bonus: {
                        connect: [
                            {
                                id: item.KPI_Bonus.id,
                                // Name: item.KPI_Bonus.label
                            }
                        ]
                    }
                }
            }),
            Bonus_Max: parseFloat(values?.bonusMax),
            Bonus_Max_Percentage: parseFloat(values?.bonusMaxPercentage),
        };


   
    
        const totalWeightage = data.Bonus_Parts.reduce((acc, item) => acc + item.Weightage, 0);
        if (totalWeightage > 100) {
            dispatch(setErrorMessage('The sum of the weightages assigned to all KPI components must not exceed 100.'));
            return;
        }

        dispatch(setLoading(true));
        if (id) {
            makeApiRequest(`/api/user-bonuses/${id}`, 'PUT', { data }).then(resp => {
                if (resp?.status === 200 && resp?.data) {
                    dispatch(setSuccessMessage('Reportee bonus details updated successfully'));
                    setTimeout(() => {
                        setRefreshCount(refreshCount + 1);
                        navigate(`/my-reportees?reporteeId=${user?.id}&reportee=${user?.label}&employeeId=${user?.data?.employee_id}&period=${period?.label}&pId=${period?.id}`);
                    }, 3000)
                }
            }).catch((error) => {
                const respData = error?.response?.data;
                dispatch(setErrorMessage(respData?.error?.message));
                console.error('update user bonus API Error:', error);
            }).finally(() => {
                dispatch(setLoading(false));
            })
        } else {
            makeApiRequest(`/api/user-bonuses`, 'POST', { data }).then(resp => {
                if (resp?.status === 201 && resp?.data) {
                    dispatch(setSuccessMessage('Reportee bonus details created successfully'));
                    setTimeout(() => {
                        navigate(`/my-reportees?reporteeId=${user?.id}&reportee=${user?.label}&employeeId=${user?.data?.employee_id}&period=${period?.label}&pId=${period?.id}`);
                    }, 3000)
                }
            }).catch((error) => {
                const respData = error?.response?.data;
                dispatch(setErrorMessage(respData?.error?.message));
                console.error('Create user bonus API Error:', error);
            }).finally(() => {
                dispatch(setLoading(false));
            })
        }
    }

    const handleAccordionFieldsChange = (updatedEntries) => {
        setBonusParts(updatedEntries);
    };

    const handleChange = useCallback(
        (event) => {
            setErrors((prevState) => ({
                ...prevState,
                [event.target.name]: ''
            }));
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        []
    );

    const handleKeyDown = (event) => {
        if (!validateNumberField(event)) {
            event.preventDefault();
        }
    };

    const handleDelete = () => {
        if (!id) return;
        dispatch(setLoading(true));
        makeApiRequest(`/api/user-bonuses/${id}`, 'DELETE')
            .then(resp => {
                if (resp?.status === 200) {
                    setViewModal(false);
                    dispatch(setSuccessMessage('Reportee bonus details deleted successfully'));
                    setTimeout(() => {
                        navigate(`/my-reportees`);
                        // navigate(`/my-reportees?reporteeId=${user?.id}&reportee=${user?.label}&employeeId=${user?.data?.employee_id}&period=${period?.label}&pId=${period?.id}`);
                    }, 3000);
                }
            }).finally(() => {
                setViewModal(false);
                dispatch(setLoading(false));
            })
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
            }}
        >
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid xs={12} md={12}>
                        <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">
                                {`${id ? 'Update details' : 'Create an entry'}`}
                            </Typography>

                            <Button
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {`${id ? 'Update' : 'Save'}`}
                            </Button>

                        </Box>
                    </Grid>

                    <Grid xs={12} md={id ? 9 : 12}>
                        <Card>
                            <CardContent>
                                <Box>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid container spacing={3} xs={12}
                                            md={12}>
                                            <Grid
                                                xs={12}
                                                md={4}
                                            >
                                                <MUIAutocomplete
                                                    label="User"
                                                    selectedValue={user}
                                                    apiEndpoint={`api/get-reportees-list?managerId=${managerId}`}
                                                    fieldKey="username"
                                                    onSelect={(userObj) => {
                                                        setErrors((prevState) => ({
                                                            ...prevState,
                                                            user: ''
                                                        }));
                                                        setUser(userObj)
                                                    }}
                                                    helperText={<ErrorHelperText error={errors?.user} />}
                                                    required
                                                    disabled={id}
                                                />
                                            </Grid>
                                            <Grid
                                                xs={12}
                                                md={4}
                                            >
                                                <MUIAutocomplete
                                                    label="Period"
                                                    selectedValue={period}
                                                    apiEndpoint="api/get-perfomance-period-list?"
                                                    fieldKey="Name"
                                                    onSelect={(pObj) => {
                                                        setErrors((prevState) => ({
                                                            ...prevState,
                                                            period: ''
                                                        }));
                                                        setPeriod(pObj)
                                                    }}
                                                    helperText={<ErrorHelperText error={errors?.period} />}
                                                    required
                                                />
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Bonus Max"
                                                name="bonusMax"
                                                onChange={handleChange}
                                                type="number"
                                                required
                                                value={values?.bonusMax === 0 ? 0 : values?.bonusMax}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={handleKeyDown}
                                                helperText={<ErrorHelperText error={errors?.bonusMax} />}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Bonus Max Percentage"
                                                name="bonusMaxPercentage"
                                                onChange={handleChange}
                                                type="number"
                                                value={values?.bonusMaxPercentage === 0 ? 0 : values?.bonusMaxPercentage}
                                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                                required
                                                onKeyDown={handleKeyDown}
                                                helperText={<ErrorHelperText error={errors?.bonusMaxPercentage} />}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Bonus Offered"
                                                name="bonusOffered"
                                                onChange={handleChange}
                                                type="number"
                                                value={values?.bonusOffered || ''}
                                                disabled
                                                InputProps={{ inputProps: { min: 0 } }}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Bonus Percentage"
                                                name="bonusPercentage"
                                                onChange={handleChange}
                                                type="number"
                                                value={values?.bonusPercentage || ''}
                                                disabled
                                                InputProps={{ inputProps: { min: 0 } }}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={4}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Bonus Accrued"
                                                name="bonusAccrued"
                                                onChange={handleChange}
                                                type="number"
                                                value={values?.bonusAccrued || ''}
                                                disabled
                                                InputProps={{ inputProps: { min: 0 } }}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            md={4}
                                        >
                                            <FormControl variant='filled'
                                                fullWidth
                                            >
                                                <InputLabel>Status</InputLabel>
                                                <Select
                                                    value={values?.status || ''}
                                                    onChange={handleChange}
                                                    size='small'
                                                    label="Status"
                                                    name="status"
                                                    disabled
                                                >
                                                    {
                                                        STATUS_OPTIONS.map(sItem => (
                                                            <MenuItem key={sItem} value={sItem}>
                                                                {sItem}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant='subtitle2' pb={1}>{`Bonus Parts (${accordionCount})`}</Typography>
                                    <Box sx={{ border: `1px solid ${theme.palette.divider}` }}>
                                        <InputAccordion
                                            data={bonusParts}
                                            onChange={handleAccordionFieldsChange}
                                            onAccordionCountChange={(count) => { setAccordionCount(count) }}
                                            triggerValidate={triggerValidate}
                                            setTriggerValidate={setTriggerValidate}
                                            errors={errors}
                                            setErrors={setErrors}
                                            setAccordionValid={setAccordionValid}
                                        />
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    {id && <Grid xs={12} md={3}>
                        <Card sx={{ mb: 1 }}>
                            <CardContent>
                                <Typography variant='body' sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                    Editing <Typography mx={1} sx={{ fontWeight: 'bold' }} component="b">{` ${id ? 'Published' : 'Draft'} `} </Typography>  version
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Typography variant="caption">
                                    INFORMATION
                                </Typography>
                                <Divider />
                                <Stack spacing={1} sx={{ mt: 1 }}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant='subtitle2'>Created</Typography>
                                        <Typography variant='subtitle2'>{values?.createdAt ? moment(values?.createdAt).fromNow() : moment().fromNow()}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant='subtitle2'>By</Typography>
                                        <Typography variant='subtitle2'>{values?.created_by?.username || '-'}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant='subtitle2'>Last update</Typography>
                                        <Typography variant='subtitle2'>{values?.updatedAt ? moment(values?.updatedAt).fromNow() : moment().fromNow()}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant='subtitle2'>By</Typography>
                                        <Typography variant='subtitle2'>{values?.updated_by?.username || '-'}</Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>

                        <Button
                            fullWidth
                            color='error'
                            variant="outlined"
                            type="submit"
                            onClick={() => { setViewModal(true) }}
                            sx={{ mt: 1 }}
                        >
                            Delete this Entry
                        </Button>
                    </Grid>}
                </Grid>

                <MUIModal
                    open={viewModal}
                    handleClose={() => {
                        setViewModal(false)
                    }}
                >
                    <Box pt={1}>
                        <Typography variant='title'>
                            You are about to delete this entry. Do you want to proceed?
                        </Typography>

                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} flexWrap="wrap" py={1}>
                            <Button
                                size="small"
                                variant="outlined"
                                type="submit"
                                onClick={() => { setViewModal(false) }}
                                sx={{ mt: 1 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                color='error'
                                variant="contained"
                                type="submit"
                                onClick={handleDelete}
                                sx={{ mt: 1 }}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </MUIModal>
            </Container>
        </Box>
    )
}

export default CreateUserBonus