import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';


export const commonRouteItems = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Salary',
    path: '/salary-navigation',
    icon: <StackedLineChartOutlinedIcon />
  },
  {
    title: 'My KPI Bonus',
    path: '/my-kpi-bonus',
    icon: <ViewListIcon />
  },
  {
    title: 'Notifications',
    path: '/notifications',
    icon: <NotificationsIcon />
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: <SettingsIcon />
  },
];

export const additionalRouteItems = {
  Employee: [],
  Manager: [
    {
      title: 'My Reportees',
      path: '/my-reportees',
      icon: <GroupIcon />
    },
  ],
};