import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid, Container, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Box, Button,
    Typography, IconButton, Card, CardContent, Tooltip, CardMedia, CardHeader
} from '@mui/material';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MUIAutocomplete from './Common/MUIAutoComplete';
import MUIModal from './Common/MUIModal';
import { BASE_URL } from '../utils/constants';
import { getBonusPartName, removeNonValueFilters } from '../utils';
import makeApiRequest from '../Redux/api';
import { setErrorMessage, setLoading, setSuccessMessage } from '../Redux/slices/uiSlice';
import Proofs from './Proofs';
import Loader from './Common/Loader';
import GroupIcon from '@mui/icons-material/Group';


const RenderCalculationButton = ({ reporteeData, handleProceedCalculation }) => {
    const checkBonusPartsStatus = (bonusParts) => {
        const allApproved = bonusParts.every((item) => item.status === "Approved");
        const someApprovedAndSomeRejectedDisagreed = bonusParts.some(
            (item) => item.status === "Approved"
        ) && bonusParts.some(
            (item) => ["Rejected", "Disagreed"].includes(item.status)
        ) && bonusParts.every(
            (item) =>
                ["Approved", "Rejected", "Disagreed"].includes(item.status)
        );

        if (allApproved || someApprovedAndSomeRejectedDisagreed) {
            return true;
        }

        return false;
    }

    const result = checkBonusPartsStatus(reporteeData?.Bonus_Parts);

    return (
        result && reporteeData?.Status === 'Pending'
            ?
            <Button
                variant="contained"
                type="submit"
                onClick={handleProceedCalculation}
                size="small"
            >
                Process Bonus Calculation
            </Button>
            : '')
}

export default function MyReportees({ managerId }) {
    const loading = useSelector((state) => state.ui.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let reporteesContent = null;
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState([]);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const [reporteeData, setReporteeData] = useState();
    const [proofs, setProofs] = useState([]);
    const [reason, setReason] = useState();
    const [viewProofs, setViewProofs] = useState(false);
    const [viewReason, setViewReason] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [selectedReportee, setSelectedReportee] = useState(
        (existingParams?.reportee &&
            existingParams?.reporteeId && existingParams?.employeeId) ? {
            id: existingParams?.reporteeId,
            label: existingParams?.reportee,
            data: {
                employee_id: existingParams?.employeeId
            }
        } : null);
    const [selectedPeriod, setSelectedPeriod] = useState(
        existingParams?.period &&
            existingParams?.pId ? {
            id: existingParams?.pId,
            label: existingParams?.period,
            data: {}
        } : null);


    /*
        const fetchData = async (nextPage) => {
            dispatch(setLoading(true));
            try {
                const resp = await axios.get(`/api/user-bonuses?page=${nextPage}`);
                const { results, pagination } = resp?.data;
                const newData = results.filter(
                    (newItem) => !data.some((existingItem) => existingItem.id === newItem.id)
                );
                setData((prevData) => [...prevData, ...newData]);
                setTotalPages(pagination?.pageCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                dispatch(setLoading(false));
            }
        };
    */
    const fetchReporteeData = async () => {
        // dispatch(setLoading(true));
        setIsLoading(true);
        try {
            let queryStr = `employeeId=${selectedReportee?.data?.employee_id}`;
            if (existingParams?.pId) {
                queryStr = `${queryStr}&period=${existingParams.pId}`
            }
            const resp = await makeApiRequest(`/api/get-user-bonus-info?${queryStr}`);
            if (resp?.status === 200) {
                const respData = resp?.data?.data;
                setReporteeData(respData);
            }
        } catch (error) {
            const respData = error?.response?.data;
             dispatch(setErrorMessage(respData?.error?.message));
            console.error('Error fetching reportee user bonus data:', error);
        } finally {
            // dispatch(setLoading(false));
            setIsLoading(false);
        }
    };

    const handleUpdateBonusParts = async (part, status) => {
        const data = {
            id: reporteeData?.id,
            userId: selectedReportee?.data?.employee_id,
            Bonus_Parts: {
                ...part,
                status
            },
            actionByRole: 'Manager'
        }
        dispatch(setLoading(true));
        makeApiRequest(`/api/user-bonus-parts-update`, 'POST', { data })
            .then(resp => {
                if (resp?.status === 200) {
                    // const respData = resp?.data?.data;
                    fetchReporteeData();
                }
            })
            .catch(err => {
                console.error('Error updating the reportees bonus part data:', err);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });

/*
        try {
            const resp = await axios.post(`/api/user-bonus-parts-update`, { data });
            if (resp?.status === 200) {
                // const respData = resp?.data?.data;
                fetchReporteeData();

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            dispatch(setLoading(false));
        }
        */
    }

    const handleProceedCalculation = async () => {
        const data = {
            id: reporteeData?.id,
        }
        dispatch(setLoading(true));
        makeApiRequest(`/api/proceed-calculation`, 'POST', { data })
            .then(resp => {
                if (resp?.status === 200) {
                    const respData = resp?.data
                    dispatch(setSuccessMessage(respData?.message));
                    setRefreshCount(refreshCount + 1);
                }
            })
            .catch(error => {
                const respData = error?.response?.data;
                let errorMsg = 'Something went wrong. Try again later';
                if (respData?.error?.message) {
                    errorMsg = respData?.error?.message;
                }
                dispatch(setErrorMessage(errorMsg));
                console.error('Proceed calculation API Error:', error);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    }

    const handleViewProof = (part) => {
        setProofs(part?.jsonData?.proofs);
        setViewProofs(true);
    }
    const handleViewReason = (part) => {
        setReason(part?.jsonData?.reason);
        setViewReason(true);
    }

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    // useEffect(() => {
    //     fetchData(page);
    // }, [page])

    useEffect(() => {

        if (
            !existingParams?.reporteeId ||
            !existingParams?.reportee ||
            !existingParams?.employeeId ||
            !existingParams?.pId ||
            !existingParams?.period
        ) {
            const params = {
                reporteeId: existingParams?.reporteeId || '',
                reportee: existingParams?.reportee || '',
                employeeId: existingParams?.employeeId || '',
                period: existingParams?.period || '',
                pId: existingParams?.pId || '',
            };
            setQueryParams(params);
            return;
        }

        setSelectedReportee({
            id: existingParams.reporteeId,
            label: existingParams.reportee,
            data: {
                employee_id: existingParams.employeeId,
            },
        });
        setSelectedPeriod({
            id: existingParams.pId,
            label: existingParams.period,
            data: {},
        });
    }, [
        existingParams.reporteeId,
        existingParams.reportee,
        existingParams.employeeId,
        existingParams.pId,
        existingParams.period,
    ]);


    /*
        useEffect(() => {
            if (!existingParams?.page) return;
            setPage(Number(existingParams?.page));
        }, [existingParams?.page]);
    */
    useEffect(() => {
        if (!selectedReportee || !selectedPeriod) return;
        fetchReporteeData();
    }, [selectedReportee, selectedPeriod, refreshCount]);


    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 2,
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box component="div" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            <GroupIcon />
                            <Typography color="inherit" variant="h6" sx={{ ml: 1 }}>
                                My Reportees
                            </Typography>
                        </Box>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item container direction="row" alignItems="center" justifyContent="space-between" flexWrap={{ xs: 'wrap', md: 'nowrap' }} spacing={2}>
                            <Grid item xs={12} md={3}>
                                <MUIAutocomplete
                                    label="Reportees"
                                    selectedValue={selectedReportee}
                                    apiEndpoint={`api/get-reportees-list?managerId=${managerId}`}
                                    fieldKey="username"
                                    onSelect={(userObj) => {
                                        updateQueryParams({
                                            reporteeId: userObj?.id ? userObj?.id : '',
                                            reportee: userObj?.label ? userObj?.label : '',
                                            employeeId: userObj?.data?.employee_id ? userObj?.data?.employee_id : ''
                                        })
                                        setSelectedReportee(userObj);
                                    }} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={3}
                            >
                                {selectedReportee && <MUIAutocomplete
                                    label="Period"
                                    selectedValue={selectedPeriod}
                                    apiEndpoint="api/get-perfomance-period-list?"
                                    fieldKey="Name"
                                    onSelect={(pObj) => {
                                        updateQueryParams({
                                            pId: pObj?.id ? pObj?.id : '',
                                            period: pObj?.label ? pObj?.label : '',
                                        })
                                        setSelectedPeriod(pObj)
                                    }}
                                />}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box component="div" display="flex" alignItems="center" justifyContent="flex-end" sx={{ mb: 1, width: '100%' }}>
                                    {managerId && selectedReportee && reporteeData && <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={() => { navigate(`/create-user-bonus/${reporteeData?.id}`) }}
                                        size="small"
                                        sx={{ mr: 2 }}
                                    >
                                        Edit
                                    </Button>}
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        onClick={() => { navigate(`/create-user-bonus`) }}
                                        size="small"
                                    >
                                        Create New Entry
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        {isLoading && selectedReportee && selectedPeriod && !reporteeData && <Loader/>}
                        {!isLoading && selectedReportee && selectedPeriod && !reporteeData && <div style={{ width: '100%', paddingTop: 35 }}>
                            <Grid item container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12} md={12} lg={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant='h6' textAlign="center">Bonus details not available</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>}
                        {selectedReportee && selectedPeriod && reporteeData && <>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader
                                        title="Employee Bonus Details"
                                        action={
                                            <RenderCalculationButton 
                                                reporteeData={reporteeData} 
                                                handleProceedCalculation={handleProceedCalculation}
                                            />
                                        }
                                    />
                                    <CardContent sx={{ pt: 0 }}>
                                        <Grid container item xs={12} md={12} spacing={2}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Employee Id
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.User.employee_id || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Period
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.Period?.Name || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Employee
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.User.username || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Bonus Offered
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.Bonus_Offered || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Bonus Percentage
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.Bonus_Percentage || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Bonus Max
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.Bonus_Max || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Bonus Accrued
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.Bonus_Received || '-'}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    variant="body2"
                                                >
                                                    Status
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    {reporteeData?.Status || '-'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Component</TableCell>
                                                <TableCell>Weightage</TableCell>
                                                <TableCell>Compensation</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                reporteeData?.Bonus_Parts.map(part => {
                                                    const regex = /=/;
                                                    let comParts = regex.test(part.Display_Text) ? part.Display_Text.split('=') : '-';
                                                    const compensation = regex.test(part.Display_Text) ? comParts[comParts.length - 1] : '-';

                                                    return (
                                                        <TableRow
                                                            key={part.id}
                                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell>{getBonusPartName(part.Display_Text)}</TableCell>
                                                            <TableCell>{part.Weightage}</TableCell>
                                                            <TableCell>{part.status === 'Approved' && part?.Bonus_Received ? part?.Bonus_Received : compensation.trim()}</TableCell>
                                                            <TableCell>{part.status}</TableCell>
                                                            <TableCell align="center">

                                                                {
                                                                    (part.status === 'Added' || part.status === 'Updated' || part.status === 'Disagreed') &&
                                                                    <>
                                                                        {/* <IconButton onClick={() => { navigate(`/create-user-bonus/${managerId}/${reporteeData?.id}`) }}>
                                                                            <EditIcon />
                                                                        </IconButton> */}
                                                                        {
                                                                            part.status === 'Disagreed' && <Tooltip title="View Reason">
                                                                                <IconButton onClick={() => { handleViewReason(part) }}>
                                                                                    <VisibilityIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                    </>
                                                                }

                                                                {
                                                                    (part.status === 'Agreed') && <Button
                                                                        variant="contained"
                                                                        type="submit"
                                                                        onClick={() => { handleUpdateBonusParts(part, 'Locked') }}
                                                                        size="small"
                                                                    >
                                                                        Lock
                                                                    </Button>
                                                                }

                                                                {
                                                                    (part.status === 'Locked') && <Box component="div" display="flex" alignItems="center" justifyContent="flex-start" rowGap={1}>
                                                                        <Button
                                                                            // color="primary"
                                                                            variant="contained"
                                                                            type="submit"
                                                                            onClick={() => { handleUpdateBonusParts(part, 'Approved') }}
                                                                            size="small"
                                                                            sx={{ mr: 2 }}
                                                                        >
                                                                            Approve
                                                                        </Button>
                                                                        <Button
                                                                            type="submit"
                                                                            onClick={() => { handleUpdateBonusParts(part, 'Proof Requested') }}
                                                                            size="small"

                                                                        >
                                                                            Request Proof
                                                                        </Button>
                                                                    </Box>
                                                                }
                                                                {
                                                                    (part.status === 'Proof Submitted' || part.status === 'Approved' || part.status === 'Rejected') &&
                                                                    <Box component="div" display="flex" alignItems="center" justifyContent="flex-start" rowGap={1}>
                                                                        {part.status === 'Proof Submitted' && <>
                                                                            <Button
                                                                                // color="primary"
                                                                                type="submit"
                                                                                onClick={() => { handleUpdateBonusParts(part, 'Rejected') }}
                                                                                size="small"
                                                                                sx={{ mr: 2 }}
                                                                            >
                                                                                Reject
                                                                            </Button>
                                                                            <Button
                                                                                variant="contained"
                                                                                type="submit"
                                                                                onClick={() => { handleUpdateBonusParts(part, 'Approved') }}
                                                                                size="small"
                                                                                sx={{ mr: 2 }}
                                                                            >
                                                                                Approve
                                                                            </Button>
                                                                        </>}
                                                                        {part?.jsonData?.proofs?.length > 0 && <Tooltip title="View Proofs">
                                                                            <IconButton onClick={() => { handleViewProof(part) }}>
                                                                                <VisibilityIcon />
                                                                            </IconButton>
                                                                        </Tooltip>}
                                                                    </Box>
                                                                }

                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </>}
                    </Grid>
                    {viewProofs && proofs && <MUIModal
                        maxWidth='md'
                        title="Proofs"
                        open={viewProofs}
                        handleClose={() => {
                            setProofs([])
                            setViewProofs(false)
                        }}
                    >
                        <Proofs proofs={proofs} />
                    </MUIModal>
                    }
                    {viewReason && reason && <MUIModal
                        width='500px'
                        open={viewReason}
                        title={'Reason for Disagreement'}
                        handleClose={() => {
                            setReason(null);
                            setViewReason(false)
                        }}
                    >
                        <Box sx={{ minWidth: 400 }} py={2}>
                            <Typography>
                                {reason}
                            </Typography>
                        </Box>
                    </MUIModal>
                    }
                </Container>
            </Box>
        </>
    )
}