import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Grid, Container,
    TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Box, Button, Dialog, DialogActions, DialogContent, TextField,
    Typography, Card, CardContent, Tooltip, IconButton, CardMedia, CardHeader,
} from '@mui/material';
import UploadFiles from './UploadFiles';
import MUIModal from './Common/MUIModal';
import { getBonusPartName, removeNonValueFilters } from '../utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setErrorMessage, setLoading } from '../Redux/slices/uiSlice';
import MUIAutocomplete from './Common/MUIAutoComplete';
import Proofs from './Proofs';
import makeApiRequest from '../Redux/api';
import Loader from './Common/Loader';
import ViewListIcon from '@mui/icons-material/ViewList';

function DisagreeDialog({ open = false, handleClose, onSubmit }) {
    const [reason, setReason] = useState('');

    const handleSubmit = () => {
        const trimmedReason = reason.trim();
        if (trimmedReason) {
            onSubmit(trimmedReason);
            handleClose();
        }
    };

    return (
        <MUIModal
            open={open}
            handleClose={handleClose}
        >
            <Box sx={{ minWidth: 500 }}>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="reason"
                    name="reason"
                    label="Reason for disagreement"
                    fullWidth
                    variant="filled"
                    multiline
                    minRows={5}
                    value={reason}
                    onChange={evt => setReason(evt.target.value)}
                />
                <DialogActions sx={{ p: 0, pt: 0.8 }}>
                    <Button onClick={handleClose} sx={{ textTransform: "none" }}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ textTransform: "none" }}
                        disabled={reason.trim().length < 3}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Box>
        </MUIModal>
    );
}

export default function KpiBonus() {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.ui.loading);
    const user = useSelector((state) => state.auth.user);
    const userRole = useSelector((state) => state.auth.user?.role?.name);
    const [isLoading, setIsLoading] = useState(false);
    const [employeeId, setEmployeeId] = useState();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const [reporteeData, setReporteeData] = useState();
    const [selectedData, setSelectedData] = useState();
    const [showUpload, setShowUpload] = useState(false);
    const [proofs, setProofs] = useState([]);
    const [viewProofs, setViewProofs] = useState(false);
    const [viewReason, setViewReason] = useState(false);
    const [reason, setReason] = useState();
    const [selectedPeriod, setSelectedPeriod] = useState(
        existingParams?.period &&
            existingParams?.pId ? {
            id: existingParams?.pId,
            label: existingParams?.period,
            data: {}
        } : null);

    const handleViewProof = (part) => {
        setProofs(part?.jsonData?.proofs);
        setViewProofs(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fetchData = async () => {
        // dispatch(setLoading(true));
        setIsLoading(true)

        let queryStr = `employeeId=${employeeId}`;
        if (existingParams?.pId) {
            queryStr = `${queryStr}&period=${existingParams.pId}`
        }
        makeApiRequest(`/api/get-user-bonus-info?${queryStr}`).then(resp => {
            if (resp?.status === 200) {
                const respData = resp?.data?.data;
                setReporteeData(respData);
                if (!existingParams.pId && respData?.Period) {
                    setSelectedPeriod({
                        id: respData?.Period?.id,
                        label: respData?.Period?.Name,
                        data: {}
                    });
                }
            }
        }).catch(err => {
            console.error('Error fetching kpi bonus data:', err);
        }).finally(() => {
            // dispatch(setLoading(false));
            setIsLoading(false);
        });

        /*
        try {
            let queryStr = `employeeId=${employeeId}`;
            if (existingParams?.pId) {
                queryStr = `${queryStr}&period=${existingParams.pId}`
            }
            const resp = await axios.get(`/api/get-user-bonus-info?${queryStr}`);
            if (resp?.status === 200) {
                const respData = resp?.data?.data;
                setReporteeData(respData);
                if (!existingParams.pId && respData?.Period) {
                    setSelectedPeriod({
                        id: respData?.Period?.id,
                        label: respData?.Period?.Name,
                        data: {}
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            dispatch(setLoading(false));
        }
        */
    };

    const handleUpdateBonusParts = async (part, status, reason = null) => {
        if (reason) {
            part.jsonData = Object.assign({}, part?.jsonData, { reason })
        }
        const data = {
            employeeId,
            id: reporteeData?.id,
            Bonus_Parts: {
                ...part,
                status,
            },
            actionByRole:'Reportee'
        }
        dispatch(setLoading(true));
        makeApiRequest(`/api/user-bonus-parts-update`,'POST', { data }).then(resp=>{
            if (resp?.status === 200) {
                const respData = resp?.data?.data;
                fetchData()
            }
        }).catch(err=>{
            console.error('Error updating kpi bonus part data:', err);
        }).finally(()=>{
            dispatch(setLoading(false));
        })
        /*
        try {
            const resp = await axios.post(`/api/user-bonus-parts-update`, { data });
            if (resp?.status === 200) {
                const respData = resp?.data?.data;
                fetchData()
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            dispatch(setLoading(false));
        }
        */
    }

    const handleFilesUpload = async (files) => {
        if (!files.length) {
            dispatch(setErrorMessage('Please select one or more files to upload.'));
            return;
        }
        dispatch(setLoading(true));
        const formData = new FormData();
        const data = {
            employeeId,
            id: reporteeData?.id,
            Bonus_Parts: {
                ...selectedData,
                status: 'Proof Submitted',
            },
            actionByRole:'Reportee'
        }

        formData.append('data', JSON.stringify(data));

        for (const file of files) {
            formData.append('files', file);
        }

        makeApiRequest(`/api/upload-bonus-parts-proof`,'POST',formData).then(resp=>{
            dispatch(setLoading(false));
            handleUploadClose();
            fetchData();
        }).catch(err=>{
            console.error('Error uploading files:', err);
            handleUploadClose();
            const respData = err?.response?.data;
            dispatch(setLoading(false));
            dispatch(setErrorMessage(respData?.error?.message));
        });
        /*
                try {
                    const response = await axios.post(`/api/upload-bonus-parts-proof`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    // console.log('Files uploaded successfully:', response.data);
                    dispatch(setLoading(false));
                    handleUploadClose();
                    fetchData();
                } catch (error) {
                    console.error('Error uploading files:', error);
                    const respData = error?.response?.data;
                    dispatch(setLoading(false));
                    dispatch(setErrorMessage(respData?.error?.message));
                }
        */
    };

    const handleUploadClose = () => {
        setSelectedData(null);
        setShowUpload(false)
    }

    const handleViewReason = (part) => {
        setReason(part?.jsonData?.reason);
        setViewReason(true);
    }

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    useEffect(() => {
        if (!user?.employee_id) return;
        setEmployeeId(user?.employee_id)
    }, [user?.employee_id]);

    useEffect(() => {
        if (!employeeId) return;
        fetchData();
    }, [employeeId, existingParams.pId])

    // useEffect(() => {
    //     if (!existingParams?.page) return;
    //     setPage(Number(existingParams?.page));
    // }, [existingParams?.page])

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 2,
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box component="div" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            <ViewListIcon />
                            <Typography color="inherit" variant="h6" sx={{ ml: 1 }}>
                                My KPI Bonus
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} pb={2}>
                        <Grid item container direction="row" alignItems="center" justifyContent="space-between" flexWrap={{ xs: 'wrap', md: 'nowrap' }} spacing={2}>
                            <Grid item xs={12} md={3}>
                                {((existingParams?.period && existingParams?.pId) || reporteeData) && <MUIAutocomplete
                                    label="Period"
                                    selectedValue={selectedPeriod}
                                    apiEndpoint="api/get-perfomance-period-list?"
                                    fieldKey="Name"
                                    onSelect={(pObj) => {
                                        updateQueryParams({
                                            pId: pObj?.id ? pObj?.id : '',
                                            period: pObj?.label ? pObj?.label : '',
                                        })
                                        setSelectedPeriod(pObj)
                                    }}
                                />}
                            </Grid>
                        </Grid>
                    </Grid>
                    {isLoading && !reporteeData && <Loader/>}
                    {!isLoading && !reporteeData && <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant='h6' textAlign="center">Bonus details not available</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>}

                    {reporteeData && <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader
                                    title="Bonus Details"
                                />
                                <CardContent sx={{ pt: 0 }}>
                                    <Grid container item xs={12} md={12} spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Employee Id
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.User?.employee_id || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Period
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.Period?.Name || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Employee
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.User.username || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Bonus Percentage
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.Bonus_Percentage || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Bonus Offered
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.Bonus_Offered || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Bonus Max
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.Bonus_Max || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Bonus Accrued
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.Bonus_Received || '-'}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Status
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="title"
                                            >
                                                {reporteeData?.Status || '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Component</TableCell>
                                            <TableCell>Weightage</TableCell>
                                            <TableCell>Compensation</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            reporteeData?.Bonus_Parts.map(part => {
                                                const regex = /=/;
                                                let comParts = regex.test(part.Display_Text) ? part.Display_Text.split('=') : '-';
                                                const compensation = regex.test(part.Display_Text) ? comParts[comParts.length - 1] : '-';
                                                return (
                                                    <TableRow
                                                        key={part.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{getBonusPartName(part.Display_Text)}</TableCell>
                                                        <TableCell>{part.Weightage}</TableCell>
                                                        <TableCell>{part.status === 'Approved' && part?.Bonus_Received ? part?.Bonus_Received : compensation.trim()}</TableCell>
                                                        <TableCell>{part.status}</TableCell>
                                                        <TableCell align="center">
                                                            {
                                                                (part.status === 'Added' || part.status === 'Updated') && <Box component="div" display="flex" alignItems="center" justifyContent="flex-start">
                                                                    <Button
                                                                        variant="contained"
                                                                        type="submit"
                                                                        onClick={() => { handleUpdateBonusParts(part, 'Agreed') }}
                                                                        size="small"
                                                                        sx={{ mr: 2 }}
                                                                    >
                                                                        Agree
                                                                    </Button>
                                                                    <Button
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            setSelectedData(part)
                                                                            handleClickOpen()
                                                                        }}
                                                                        size="small"

                                                                    >
                                                                        Disagree
                                                                    </Button>
                                                                </Box>
                                                            }
                                                            {
                                                                (part.status === 'Proof Requested' || part.status === 'Rejected') && <Button
                                                                    variant="contained"
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        setSelectedData(part);
                                                                        setShowUpload(true);
                                                                    }}
                                                                    size="small"
                                                                    style={{ margin: 'auto' }}

                                                                >
                                                                    Submit Proof
                                                                </Button>
                                                            }
                                                            {
                                                                (part.status === 'Proof Submitted' && part?.jsonData?.proofs?.length > 0) && <Tooltip title="View Proofs">
                                                                    <IconButton onClick={() => { handleViewProof(part) }}>
                                                                        <VisibilityIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }

                                                            {
                                                                part.status === 'Disagreed' && <Tooltip title="View Reason">
                                                                    <IconButton onClick={() => { handleViewReason(part) }}>
                                                                        <VisibilityIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>}
                    {open && selectedData && <DisagreeDialog open={open} handleClose={handleClose} onSubmit={(reason) => {
                        handleUpdateBonusParts(selectedData, 'Disagreed', reason)
                    }} />}

                    {showUpload && selectedData &&
                        <UploadFiles
                            open={showUpload}
                            handleClose={handleUploadClose}
                            onSubmit={handleFilesUpload}
                        />
                    }

                    {viewProofs && proofs && <MUIModal
                        maxWidth='md'
                        title="Proofs"
                        open={viewProofs}
                        handleClose={() => {
                            setProofs([])
                            setViewProofs(false)
                        }}
                    >
                        <Proofs proofs={proofs} />
                    </MUIModal>
                    }
                    {viewReason && reason && <MUIModal
                        open={viewReason}
                        title={'Reason for Disagreement'}
                        handleClose={() => {
                            setReason(null);
                            setViewReason(false)
                        }}
                    >
                        <Box sx={{ minWidth: 400 }} py={2}>
                            <Typography>
                                {reason}
                            </Typography>
                        </Box>
                    </MUIModal>
                    }
                </Container>
            </Box>
        </>
    )
}