
import axios from 'axios';
import { BASE_URL } from '../utils/constants';

axios.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken && config.includeToken !== false) {
    config.headers.Authorization = `bearer ${accessToken}`;
  }

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
}, function (error) {
  console.error('Error in request:', error);
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('access_token');
      window.location.href = '/login';
    } else {
      console.error('Error in response:', error);
      return Promise.reject(error);
    }
  }
);

async function makeApiRequest(url, method = 'GET', data = null, params = {}, includeToken = true) {
  try {
    const response = await axios({
      method,
      url, //`${BASE_URL}${url}`,
      data,
      params,
      includeToken,
    });
    return response;
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
}
export default makeApiRequest;