import React, { useState, useEffect } from 'react';
import {
    Button, TextField, Accordion, AccordionSummary, AccordionDetails, Typography, Grid,
    Box, useTheme, Stack, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MUIAutocomplete from './Common/MUIAutoComplete';
import ErrorHelperText from './Common/ErrorHelperText';
import { validateNumberField } from '../utils';

const InputAccordion = ({ data, onChange, onAccordionCountChange, triggerValidate, setTriggerValidate, errors, setErrors, setAccordionValid }) => {
    const [entries, setEntries] = useState(data || []);
    const [count, setCount] = useState(data?.length || 0);
    const theme = useTheme();

    useEffect(() => {
        if (data) {
            setEntries(data);
            setCount(data.length);
            onAccordionCountChange(data.length);
        }
    }, [data, onAccordionCountChange]);

    useEffect(() => {
        if (triggerValidate) {
            const isValid = validateEntries();
            setAccordionValid(isValid);
            setTriggerValidate(false);
        }
    }, [triggerValidate, setTriggerValidate, setAccordionValid]);

    const validateEntries = () => {
        let isValid = true;
        const newErrors = entries.map((entry) => {
            const entryErrors = {};
            if (entry.Weightage === null || entry.Weightage === undefined || entry.Weightage === '') {
                entryErrors.Weightage = 'This field is required.';
                isValid = false;
            }else if(Number(entry.Weightage) <= 0){
                entryErrors.Weightage = 'Value must be greater than 0';
                isValid = false;
            }else if(Number(entry.Weightage) > 100){
                entryErrors.Weightage = 'Value must be less than 100';
                isValid = false;
            }

            if (!entry.KPI_Bonus) {
                entryErrors.KPI_Bonus = 'This field is required.';
                isValid = false;
            }
            return entryErrors;
        });

        setErrors((prevErrors) => ({
            ...prevErrors,
            bonusParts: newErrors,
        }));
        return isValid;
    };


    const addEntry = () => {
        const newEntry = { id: count, Weightage: 0, KPI_Bonus: '', title: '' };
        const updatedEntries = [...entries, newEntry];
        setEntries(updatedEntries);
        setCount(count + 1);
        onAccordionCountChange(count + 1);
        onChange(updatedEntries);
        setErrors((prevErrors) => ({
            ...prevErrors,
            bonusParts: [...prevErrors.bonusParts, {}],
        }));
    };

    const removeEntry = (id) => {
        const updatedEntries = entries.filter((_,index) => index !== id);
        setEntries(updatedEntries);
        setCount(updatedEntries.length);
        onAccordionCountChange(updatedEntries.length);
        onChange(updatedEntries);

        const newErrors = errors.bonusParts.filter((_, index) => index !== id);
        setErrors((prevErrors) => ({
            ...prevErrors,
            bonusParts: newErrors,
        }));
    };

    const handleFieldChange = (id, field, value) => {
        const updatedEntries = entries.map((entry) =>
            entry.id === id ? { ...entry, [field]: value } : entry
        );
        setEntries(updatedEntries);
        onChange(updatedEntries);

        const entryIndex = entries.findIndex(entry => entry.id === id);
        const newErrors = [...errors.bonusParts];
        if (entryIndex !== -1) {  
            if (!newErrors[entryIndex]) {
                newErrors[entryIndex] = {};
            }
            if (!value) {
                newErrors[entryIndex][field] = 'This field is required.';
            } else if (field === 'Weightage' && Number(value) <= 0) {
                newErrors[entryIndex][field] = 'Value must be greater than 0';
            } else if (field === 'Weightage' && Number(value) > 100) {
                newErrors[entryIndex][field] = 'Value must be less than 100';
            }else {
                delete newErrors[entryIndex][field];
            }
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            bonusParts: newErrors,
        }));
    };

    const handleKeyDown = (event) => {
        if (!validateNumberField(event)) {
            event.preventDefault();
        } 
    };
    const selectedOptions = entries.map(entry => entry.KPI_Bonus?.label).filter(Boolean);

    return (
        <>
            {entries.map((entry, idx) => {
                return (
                <Accordion key={`accord-${idx}`} defaultExpanded disableGutters elevation={0} square sx={{ border: `1px solid ${theme.palette.divider}`, borderLeft: 0, borderRight: 0 }}>
                    <AccordionSummary
                        expandIcon={<IconButton><ExpandMoreIcon /></IconButton>}
                        aria-controls={`panel${idx}a-content`}
                        id={`panel${idx}a-header`}
                        sx={{ my: 0, minHeight: 40, flexDirection: 'row-reverse' }}>
                        <Typography variant='body2'>
                            {entry?.title}
                        </Typography>
                        <Box sx={{ marginLeft: 'auto' }}>
                            <IconButton onClick={() => removeEntry(idx)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item container xs={12} md={12} spacing={3}>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    fullWidth
                                    label="Weightage"
                                    onChange={(e) => { handleFieldChange(entry.id, 'Weightage', e.target.value); }}
                                    value={entry.Weightage === 0 ? 0 : entry.Weightage }
                                    type='number'
                                    InputProps={{ inputProps: { min: 0, max:100} }}
                                    disabled={entry?.status === 'Locked' || entry?.status === 'Approved' || entry?.status === 'Proof Requested' || entry?.status === 'Proof Submitted' || entry?.status === 'Rejected'}
                                    required
                                    helperText={
                                        errors?.bonusParts[idx]?.Weightage && <ErrorHelperText error={errors?.bonusParts[idx]?.Weightage} />
                                    }
                                    onKeyDown={handleKeyDown}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <MUIAutocomplete
                                    label="KPI Bonus"
                                    selectedValue={entry.KPI_Bonus || null}
                                    apiEndpoint="api/get-kpi-list?"
                                    fieldKey="Name"
                                    onSelect={(obj) => handleFieldChange(entry.id, 'KPI_Bonus', obj)}
                                    disabled={entry?.status === 'Locked' || entry?.status === 'Approved' || entry?.status === 'Proof Requested' || entry?.status === 'Proof Submitted' || entry?.status === 'Rejected' }
                                    required
                                    helperText={
                                        errors?.bonusParts[idx]?.KPI_Bonus && <ErrorHelperText error={errors?.bonusParts[idx]?.KPI_Bonus} />
                                    }
                                    selectedOptions={selectedOptions}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            )})}
            <Box display="flex" alignItems="center" justifyContent="center" my={2}>
                {count === 0 ? (
                    <Stack direction="column" alignItems="center">
                        <AddCircleOutlineIcon fontSize='large' onClick={addEntry} style={{ cursor: 'pointer' }} />
                        <Typography variant='body2'>
                            No entry yet. Click on the button to add one.
                        </Typography>
                    </Stack>
                ) : (
                    <Button onClick={addEntry}>
                        Add Bonus Part
                    </Button>
                )}
            </Box>
        </>
    );
};

export default InputAccordion;
