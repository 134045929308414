import { useState, useEffect } from 'react';
import {
    Box, Container, Typography, Stack,
    Unstable_Grid2 as Grid,
    // Grid,
    Tabs as MuiTabs, Tab,

} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import ChangePassword from './ChangPassword';
import ProfileDetails from './ProfileDetails';
import { removeNonValueFilters } from '../../utils';
import SettingsIcon from '@mui/icons-material/Settings';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`settings-tabpanel-${index}`}
            aria-labelledby={`settings-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Settings() {
    const [value, setValue] = useState(0);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);

    useEffect(() => {
        if (!existingParams?.view) {
            setQueryParams({ view: 'profile' });
        }
        if (existingParams?.view === 'profile') {
            setValue(0);
        } else if (existingParams?.view === 'password') {
            setValue(1);
        }
    }, [existingParams?.view]);

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    const handleTabChange = (newValue) => {
        let view = '';
        if (newValue === 1) {
            view = 'password';
        } else {
            view = 'profile';
        }
        setValue(newValue);
        updateQueryParams({ view });
    }
    
    return (
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                    px: 1
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ width: '100%' }}>
                        <Stack spacing={3}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                }}
                                mb={2}
                            >
                                <Box
                                    component="div"
                                    sx={{
                                        display: 'inline-flex',
                                        mr: 1
                                    }}
                                >
                                    <SettingsIcon />
                                </Box>
                                <div>
                                    <Typography
                                        variant="h6"
                                    >
                                        Settings
                                    </Typography>
                                </div>
                            </Box>

                            <MuiTabs value={value} onChange={(event, newValue) => { handleTabChange(newValue) }}>
                                <Tab label="Profile" value={0} />
                                <Tab label="Change Password" value={1} />
                            </MuiTabs>

                            <TabPanel value={value} index={0}>
                                <ProfileDetails />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ChangePassword />
                            </TabPanel>
                        </Stack>
                    </Box>
                </Container>
            </Box>
    )
}