import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Login from '../Auth/Login';

export default function AppRoutes() {
    return (
       <Suspense fallback={<CircularProgress />}>
            <Routes>
                {/* <Route path="/" element={<Landing />} /> */}
                <Route path="/login" element={<Login />} />
            </Routes>
       </Suspense>
    )
}
