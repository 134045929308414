import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  successMessage: null,
  errorMessage: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setData(state, action) {
        const { key, value } = action.payload;
        state[key] = value;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    clearMessages(state) {
      state.successMessage = null;
      state.errorMessage = null;
    },
  },
});

export const { setLoading, setSuccessMessage, setErrorMessage, clearMessages, setData } = uiSlice.actions;
export default uiSlice.reducer;
