import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, ListItem, ListItemText, IconButton, Grid, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setLoading } from '../Redux/slices/uiSlice';
import makeApiRequest from '../Redux/api';

const NotificationBox = ({ notification, onNotificationClick }) => {
    return (
        <ListItem
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
                borderRadius: '4px',
                backgroundColor: notification.isRead ? '#f5f5f5' : '#e0e0e0',
                cursor: 'pointer',
                marginBottom: '8px',
            }}
            onClick={() => onNotificationClick(notification)}
        >
            <ListItemText primary={<Typography variant="body2"><div dangerouslySetInnerHTML={{ __html: notification.message }} /></Typography>} />
            {notification.type !== 'Alert' && <IconButton aria-label="Open notification">
                <ChevronRightIcon />
            </IconButton>}
        </ListItem>
    );
};

const Notifications = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const loading = useSelector((state) => state.ui.loading);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchNotifications = useCallback((page) => {
        dispatch(setLoading(true));
        makeApiRequest(`/api/notifications?page=${page}`).then(resp => {
            if (resp?.status === 200) {
                const respData = resp?.data?.notifications;
                setNotifications(prev => [...prev, ...respData.results]);
                setHasMore(respData.results.length > 0 && page < respData.pagination.pageCount);
            }
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            dispatch(setLoading(false));
        });
    }, [dispatch]);

    const fetchUnreadCount = async () => {
        try {
            const resp = await makeApiRequest('/api/notifications/count');
            if (resp?.status === 200) {
                setUnreadCount(resp?.data?.count);
            }
        } catch (error) {
            console.error('Error fetching unread count:', error);
        }
    };

    const markAsRead = async (id) => {
        dispatch(setLoading(true));
        try {
            const resp = await makeApiRequest(`/api/notifications/${id}/mark-as-read`, 'PUT');
            if (resp?.status === 200) {
                fetchData();
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const markAllAsRead = () => {
        dispatch(setLoading(true));
        makeApiRequest(`/api/notifications/mark-all-as-read`, 'PUT').then(resp => {
            if (resp?.status === 200) {
                fetchData();
            }
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const clearAllNotifications = () => {
        dispatch(setLoading(true));
        makeApiRequest(`/api/notifications/clear-all`, 'PUT').then(resp => {
            if (resp?.status === 200) {
                fetchData();
            }
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const fetchData = () => {
        setNotifications([]);
        setPage(1);
        fetchNotifications(1);
        fetchUnreadCount();
    };

    const handleNotificationClick = async (notification) => {
        await markAsRead(notification.id);
        const jsonData = notification.jsonData;

        if (notification.type === 'Action' && jsonData) {
            navigate(`/my-reportees?reporteeId=${jsonData?.user.id}&reportee=${jsonData?.user.username}&employeeId=${jsonData?.user?.employee_id}&period=${jsonData?.period.Name}&pId=${jsonData?.period.id}`);
        } else if ((notification.type === 'Alert') && jsonData) {
            console.log('Alert notification type');
        } else if (notification.type === 'Info' && jsonData) {
            navigate('/my-kpi-bonus');
        } else {
            console.log('Unhandled notification type');
        }
    };

    useEffect(fetchData, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && hasMore && !loading) {
                setPage(prevPage => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore, loading]);

    useEffect(() => {
        if (page > 1) {
            fetchNotifications(page);
        }
    }, [page, fetchNotifications]);

    const unreadNotifications = notifications;
    
    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 2,
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box component="div" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            <NotificationsIcon />
                            <Typography color="inherit" variant="h6" sx={{ ml: 1 }}>
                                Notifications
                            </Typography>
                            {unreadCount > 0 && <Typography color="inherit" variant="subtitle1" sx={{ ml: 1 }}>
                                ({unreadCount} unread)
                            </Typography>}
                        </Box>
                        <Box>
                            {unreadCount > 0 && <Button variant="text" onClick={markAllAsRead} sx={{ mr: 2 }}>
                                Mark all as read
                            </Button>}
                            {unreadNotifications.length > 0 && <Button variant="text" onClick={clearAllNotifications}>
                                Clear all
                            </Button>}
                        </Box>
                    </Box>
                    {loading && page === 1 ? (
                        <Typography variant="body1">Loading...</Typography>
                    ) : unreadNotifications.length === 0 ? (
                        <Box display="flex" alignContent="center" justifyContent="center" sx={{ padding: '150px' }}>
                            <Typography variant="h6" component="div" textAlign="center">
                                No notifications available
                            </Typography>
                        </Box>

                    ) : (
                        <Grid container spacing={2}>
                            {unreadNotifications.map((notification) => (
                                <Grid item xs={12} key={notification.id}>
                                    <NotificationBox notification={notification} onNotificationClick={handleNotificationClick} />
                                </Grid>
                            ))}
                            {loading && page > 1 && (
                                <Grid item xs={12}>
                                    <Typography variant="body2">Loading more...</Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Container>
            </Box>
        </>
    );
};

export default Notifications;
