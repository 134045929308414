import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, TextField, Button, Typography, IconButton, InputAdornment, Card, CardContent, Grid } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import makeApiRequest from '../Redux/api';
import { loginSuccess } from '../Redux/slices/authSlice';
import { setSuccessMessage, setErrorMessage, setLoading } from '../Redux/slices/uiSlice';
import { validateEmail, validatePassword } from '../utils';
import ErrorHelperText from '../Components/Common/ErrorHelperText';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({ email: false, password: false });


    const fetchUserData = async (jwtToken) => {
        console.log('fetchUserData: TRIGGERS ');
        try {
          const response = await fetch('/api/users/me', {
            headers: {
              Authorization: `Bearer ${jwtToken}`
            }
          });
          const userData = await response.json();
          console.log('User Data:', userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

    const handleLogin = () => {
        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);

        setErrors({
            email: !isEmailValid,
            password: !isPasswordValid,
        });

        if (isEmailValid && isPasswordValid) {
            const data = {
                identifier: email,
                password: password
            };
            dispatch(setLoading(true));
            makeApiRequest(`/api/auth/local`, 'POST', data, null, false)
                .then((resp) => {
                    if (resp?.status === 200 && resp?.data) {
                        const respData = resp?.data;
                        dispatch(loginSuccess({
                            token: respData?.jwt,
                            user: respData?.user
                        }));
                        // fetchUserData(respData?.jwt);
                        navigate(`/dashboard?view=dash`);
                        window.location.reload();
                        dispatch(setSuccessMessage('Login successfull'));
                    }
                })
                .catch((error) => {
                    // const respData = error?.response?.data;
                    dispatch(setErrorMessage('Make sure you entered the right email address or password'));
                    console.error('Login API Error:', error);
                }).finally(()=> {
                    dispatch(setLoading(false));
                }) ;
        }
    };



    return (
        <Container component="main" maxWidth="xs">
            <Grid item xs={12} lg={12} py={25}>
                <Card>
                    <CardContent>
                        <Typography variant="h5">Login</Typography>
                        <form autoComplete="off">
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e) => {
                                    setErrors({ email: false });
                                    setEmail(e.target.value);
                                }}
                                helperText={errors.email && <ErrorHelperText error={ !email ? 'This field is required' : 'Invalid email address'} />}
                                autoComplete="off"
                            />
                            <TextField
                                label="Password"
                                margin="normal"
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => {
                                    setErrors({ password: false });
                                    setPassword(e.target.value);
                                }}
                                helperText={
                                    errors.password && <ErrorHelperText error={'Password should have at least 8 characters, a capital letter, a number, and a special symbol'} />
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off"
                            />
                            <Button variant="contained" color="primary" fullWidth onClick={handleLogin} sx={{mt:2}}>
                                Login
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>

        </Container>
    );
};

export default Login;
