import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability'
import { userRoles } from './userRoles';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export function updateAbility(roles, ability) {
    const { can, rules } = new AbilityBuilder();
    roles?.forEach(item => {
        if (item?.role?.type === userRoles.ADMIN) {
            can('view', [])
            can('manage', [])
        }

        can('view', 'Settings');
    })

    ability?.update(rules);
}

export const getAbility = () => {
    return new Ability([]);
}
