import React from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Link, Tooltip } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import { BASE_URL } from '../utils/constants';
import VisibilityIcon from '@mui/icons-material/Visibility';

const isImage = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
    return imageExtensions.some(ext => url.endsWith(ext));
};

const isPdf = (url) => url.endsWith('.pdf');

const getFileName = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
};

const truncateFileName = (filename, maxLength = 20) => {
    if (filename.length <= maxLength) return filename;
    return `${filename.substr(0, maxLength)}...`;
};

const Proofs = ({ proofs }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: '250px' }}>Preview</TableCell>
                        {/* <TableCell align="center">Comments</TableCell> */}
                          <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {proofs.map((proof) => (
                        <TableRow key={proof.id}>
                            <TableCell sx={{ width: '250px' }}>
                                <Link href={`${BASE_URL}${proof.url}`} target="_blank" rel="noopener">
                                    {isImage(`${BASE_URL}${proof.url}`) ? (
                                        <Box sx={{ textAlign: 'left' }}>
                                            <img src={`${BASE_URL}${proof.url}`} alt={`proof-${proof.id}`} style={{ maxHeight: '100px', maxWidth: '100%', objectFit: 'contain' }} />
                                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                {truncateFileName(getFileName(proof.url))}
                                            </Typography>
                                        </Box>
                                    ) : isPdf(`${BASE_URL}${proof.url}`) ? (
                                        <Tooltip title={getFileName(proof.url)} placement="top">
                                            <Box sx={{ textAlign: 'left' }}>
                                                <PictureAsPdfIcon sx={{ fontSize: 64, color: 'red' }} />
                                                <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                    {truncateFileName(getFileName(proof.url))}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={getFileName(proof.url)} placement="top">
                                            <Box sx={{ textAlign: 'left' }}>
                                                <ImageIcon sx={{ fontSize: 64, color: 'gray' }} />
                                                <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                    {truncateFileName(getFileName(proof.url))}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </Link>
                            </TableCell>
                            {/* <TableCell align='center'>-</TableCell> */}
                            <TableCell align="center">
                                <Link href={`${BASE_URL}${proof.url}`} target="_blank" rel="noopener">
                                    <Tooltip title="View Proof">
                                        <IconButton>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Proofs;
