import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AbilityContext, getAbility } from './Context/Can';
import { createTheme } from './theme';
import 'simplebar-react/dist/simplebar.min.css';
import store from './Redux/store';

const defaultTheme = createTheme();

window.gStore = store;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AbilityContext.Provider value={getAbility()}>
        <App />
      </AbilityContext.Provider>
    </ThemeProvider>
  </Provider>
);

reportWebVitals();
