import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, } from 'react-router-dom';
import Notifications from '../Components/Notifications';
import Settings from '../Components/Settings/index';
import KpiBonus from '../Components/KpiBonus';
import MyReportees from '../Components/MyReportees';
import SalaryNavigation from '../Components/Salary/SalaryNavigation';
import { Layout } from '../Components/Common/Layout';
import CreateUserBonus from '../Components/CreateUserBonus';
import { CircularProgress } from '@mui/material';

const Tabs = lazy(() => import('../Components/Tabs'));

export default function AuthRoutes({ isAuthenticated, user }) {
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return (
            <Layout isAuthenticated={isAuthenticated}>
                <Suspense fallback={<CircularProgress/>}>
                    <Routes>
                        <Route path="/notifications" element={<Notifications />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/create-user-bonus/:id?" element={<CreateUserBonus managerId={user?.id} />} />
                        <Route path="/my-reportees" element={<MyReportees managerId={user?.id}/>} />
                        <Route path="/my-kpi-bonus" element={<KpiBonus />} />
                        <Route path="/salary-navigation" element={<SalaryNavigation />} />
                        <Route path="/dashboard" element={<Tabs />} />
                    </Routes>
                </Suspense>
            </Layout>
    );
}