export const validateEmail = (value) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value);
};

export const validatePassword = (value) => {
  const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?=.{8,})/;
  return regex.test(value);
};

export const removeNonValueFilters = (filterValues:Object)=>{
    const filters = {};
    Object.keys(filterValues).forEach((key: any) => {
      if (filterValues[key] !== '') {
        Object.assign(filters, { [key]: filterValues[key] });
      }
    });
    return filters;
}

export const getBonusPartName = (str) => {
  const startIdx = str.indexOf('[');
  const bonusPart = str.substring(0, startIdx).trim();
  return bonusPart;
}

export const validateNumberField = (event) => {
  const allowedChars = /^[0-9\.\+\-\*\/]|[Backspace|Delete|ArrowUp|ArrowDown|ArrowLeft|ArrowRight]$|^$/;
  return allowedChars.test(event.key) && event.key !== 'e' && event.key !== 'E';
}