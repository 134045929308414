import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  IconButton,
  useTheme,
  Hidden,
  Tooltip,
} from '@mui/material';
import { ScrollBar } from './ScrollBar';
import { SideNavItem } from './SideNavItem';
import { commonRouteItems, additionalRouteItems } from '../../Routes/routes';
import { SIDE_NAV_WIDTH } from '../../theme';
import Logo from '../../Images/vertex-sample-logo.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../../Redux/slices/authSlice';


export const SideNav = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userRole = user?.role?.name;
  const { isOpen, onToggle } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  let routeItems = [...commonRouteItems];

  if (userRole === 'Manager') {
    const kpiBonusIndex = routeItems.findIndex(item => item.title === 'My KPI Bonus');
    routeItems.splice(kpiBonusIndex + 1, 0, ...additionalRouteItems.Manager);
  }

  const logoutBtn = (<Box sx={{ display: 'flex', mt: 1, cursor: 'pointer' }} onClick={() => { dispatch(logout()); navigate('/') }}>
    <Tooltip title="Logout">
      <LogoutIcon />
    </Tooltip>
    {/* <Typography
            color="neutral.500"
            variant="body2"
          >
            Logout
          </Typography> */}
  </Box>)

  const content = (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: isOpen ? SIDE_NAV_WIDTH : 65,
        // flexShrink: 0,
        // whiteSpace: 'nowrap',
        // boxSizing: 'border-box',
        // transition: (theme) => theme.transitions.create('width', {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
        // backgroundColor: 'neutral.800',
        // color: 'common.white',
      }}
    >
      <Box>

        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '12px'
          }}
        >
          {isOpen && <Box
            component={Link}
            to="/dashboard"
            sx={{
              textDecoration: 'none',
              display: 'inline-flex'
            }}
          >

            <img alt="logo" src={Logo} style={{
              height: 32,
              width: 32,
              marginRight: 6
            }} />

            <Typography
              // color="inherit"
              variant="subtitle1"
              sx={{ color: 'common.white' }}
            >
              Vertex
            </Typography>
          </Box>
          }
          <IconButton onClick={onToggle}>
            {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ borderColor: 'neutral.700' }} />
      <ScrollBar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'neutral.400'
          }
        }}
      >
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {routeItems.map((item) => {
              const active = item.path ? item.path.includes(pathname) : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                  isOpen={isOpen}
                  isLargeScreen={isLargeScreen}
                  onToggle={onToggle}
                />
              );
            })}
          </Stack>
        </Box>
      </ScrollBar>


      <Divider sx={{ borderColor: 'neutral.700' }} />
      <Box
        sx={{
          px: 2,
          py: 3
        }}
      >
        {isOpen && <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mx: 'auto',
          }}
        >

          <AccountCircleIcon sx={{ mr: 1 }} />
          <Typography
            color="neutral.100"
            variant="subtitle2"
          >
            {user?.username}
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            {logoutBtn}
          </div>
        </Box>}
        {!isOpen && logoutBtn}
      </Box>
    </Box>
  );

  if (isLargeScreen) {

    return (

      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.800',
            color: 'common.white',
            width: isOpen ? SIDE_NAV_WIDTH : 65,
            flexShrink: 0,
            // whiteSpace: 'nowrap',
            // boxSizing: 'border-box',
            transition: (theme) => theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }
        }}
        // open={isOpen}
      >

        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onToggle}
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.800',
          color: 'common.white',
          width: SIDE_NAV_WIDTH
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};