import {
    Box,
    IconButton,
    Stack,
    useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { alpha } from '@mui/material/styles';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '../../theme';


export const TopNav = (props) => {
    const { isOpen, onToggleSidebar } = props;
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            <Box
                component="header"
                sx={{
                    backdropFilter: 'blur(6px)',
                    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                    position: 'fixed',
                    left: {
                        lg: `${isOpen ? SIDE_NAV_WIDTH : 65}px`,
                        sm:0,
                        xs:0
                        
                    },
                    right:{
                        sm:0,
                        xs:0 
                    },
                    top: 0,
                    width: {
                        lg: `calc(100% - ${isOpen ? SIDE_NAV_WIDTH : 65}px)`
                    },
                    zIndex: (theme) => theme.zIndex.appBar,
                }}
                
  
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        minHeight: TOP_NAV_HEIGHT,
                        px: 1
                    }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={2}
                    >
                        {!isLargeScreen && (
                            <IconButton onClick={onToggleSidebar}>
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>

            </Box>

        </>
    );
};