import React, { useState, useRef } from 'react';
import { Button, Typography, DialogActions, Box, List, ListItem, ListItemText, IconButton, DialogContentText, DialogActions as ConfirmDialogActions } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIModal from './Common/MUIModal';

const UploadFiles = ({ open = false, handleClose, onSubmit }) => {
    const [files, setFiles] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [newFiles, setNewFiles] = useState(null);
    const fileInputRef = useRef(null);

    const handleBoxClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (files.length > 0) {
            setNewFiles(selectedFiles);
            setIsConfirmOpen(true);
        } else {
            setFiles(selectedFiles);
        }
        event.target.value = null;
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        if (files.length > 0) {
            setNewFiles(droppedFiles);
            setIsConfirmOpen(true);
        } else {
            setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
        }
    };

    const handleSubmit = () => {
        if (!isClicked) {
            setIsClicked(true);
            onSubmit(files);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleRemoveFile = (index) => {
        const newFilesArray = [...files];
        newFilesArray.splice(index, 1);
        setFiles(newFilesArray);
    };

    const handleConfirm = () => {
        setFiles(newFiles);
        setIsConfirmOpen(false);
        setNewFiles(null);
    };

    const handleCancel = () => {
        setIsConfirmOpen(false);
        setNewFiles(null);
    };
    return (
        <>
            <MUIModal
                open={open}
                handleClose={handleClose}
                title="Upload Proofs"
            >
                <Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            border: '2px dashed grey',
                            borderRadius: '8px',
                            padding: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onClick={handleBoxClick}
                    >
                        <UploadIcon fontSize='large' />
                        <Typography variant="body2">
                            Drag & Drop files here or click to browse
                        </Typography>
                    </Box>
                    <input
                        multiple
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                        accept=".pdf,image/jpeg,image/png,image/gif,image/bmp,image/webp"
                    />
                    <List sx={{ mt: 1, px: 0 }}>
                        {files.map((file, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={file.name} />
                                <IconButton
                                    aria-label="remove"
                                    onClick={() => handleRemoveFile(index)}
                                    disabled={isClicked}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                    <DialogActions sx={{ p: 0 }}>
                        <Button onClick={handleClose} sx={{ textTransform: "none" }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ textTransform: "none" }} disabled={files.length === 0 || isClicked}>Submit</Button>
                    </DialogActions>
                </Box>
            </MUIModal>

            <MUIModal
                open={isConfirmOpen}
                handleClose={handleCancel}
                title="Confirm File Replacement"
                maxWidth='xs'
            >
                <>
              
                <DialogContentText>
                    You have already selected files. Do you want to replace the existing files with the newly selected files?
                </DialogContentText>
                
                <ConfirmDialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} variant="contained">
                        Replace
                    </Button>
                </ConfirmDialogActions>
            </>
            </MUIModal>
        </>
    );
};

export default UploadFiles;
