export const BASE_URL = window.BASE_URL;

export const STATUS_OPTIONS = [
    'Todo',
    'Processing',
    'Completed',
    'Pending',
    'Error',
];

export const BONUS_PARTS_STATUS = {
    added:'Added',
    updated:'Updated',
    locked:'Locked',
    agreed:'Agreed',
    disAgreed:'Disagreed',
    proofRequested:'Proof Requested',
    proofSumitted:'Proof Submitted',
    approved:'Approved',
    rejected:'Rejected'
}