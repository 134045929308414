export const userRoles = {
    ADMIN: 'admin',
    EMPLOYEE: 'employee',
    EXECUTIVE: 'executive',
    MANAGER: 'manager',
}

export const ROLE_NAMES = {
    [userRoles.ADMIN]: 'Admin',
    [userRoles.EMPLOYEE]: 'Employee',
    [userRoles.EXECUTIVE]: 'Executive',
    [userRoles.MANAGER]: 'Manager',
}