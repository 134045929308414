import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Snackbar } from '@mui/material';
import './App.css';
import { loginSuccess, setUser } from './Redux/slices/authSlice';
import AuthRoutes from './Routes/AuthRoutes';
import AppRoutes from './Routes/AppRoutes';
import makeApiRequest from './Redux/api';
import { clearMessages } from './Redux/slices/uiSlice';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MUILoader from './Components/Common/MUILoader';


function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const loading = useSelector((state) => state.ui.loading);
  const successMessage = useSelector((state) => state.ui.successMessage);
  const errorMessage = useSelector((state) => state.ui.errorMessage);
  const userRole = useSelector((state) => state.auth.user?.role?.name);

  useEffect(() => {
    const getUserDetails = async () => {
      if (token && !user) {
        try {
          makeApiRequest(`/api/users/me?populate=role&populate=manager`)
            .then((resp) => {
              if (resp?.status === 200) {
                dispatch(setUser(resp?.data));
              }
            })
        } catch (error) {
          console.error('Failed to fetch user details:', error);
        }
      }
    };
    getUserDetails();
  }, [dispatch]);

  const handleCloseSnackbar = () => {
    dispatch(clearMessages());
  };
  const path = window.location.pathname;

  if (!isAuthenticated && path == '/') {
    window.location.href = '/login';
  }
  if (isAuthenticated && (path == '/' || path == '/login')) {
    window.location.href = '/dashboard';
  }
  return (
    <Box>
      <BrowserRouter>
        <AppRoutes />
        <AuthRoutes isAuthenticated={isAuthenticated} user={user} />
      </BrowserRouter>
      <MUILoader open={loading} />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // sx={{ backgroundColor: 'green', color: 'white' }}
        message={successMessage}
      />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // sx={{ backgroundColor: 'red', color: 'white' }}
        message={errorMessage}
      />
    </Box>

  );
}

export default App;
