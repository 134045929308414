import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Box, FormControl, InputLabel, Select, MenuItem, Grid, Container, Typography } from '@mui/material';
import { removeNonValueFilters } from '../../utils';
import HierarchyTable from './HierarchyTable';
import makeApiRequest from '../../Redux/api';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';


function SalaryNavigation() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const userRole = useSelector((state) => state.auth.user?.role?.name);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const [region, setRegion] = useState(existingParams?.region || '');
    const [department, setDepartment] = useState(existingParams?.department || '');
    const [regionList, setRegionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [managerData, setManagerData] = useState({});
    const [reporteesData, setReporteesData] = useState([]);


    useEffect(() => {
        makeApiRequest(`/api/get-department-region-list`).then(resp => {
            if (resp?.status === 200 && resp?.data) {
                const { departments, regions } = resp?.data;
                setDepartmentList(departments);
                setRegionList(regions);
            }
        })
    }, [])


    useEffect(() => {
        if (!user?.id) return;
        makeApiRequest(`/api/users/${user?.id}?populate=employee_salary`).then(resp => {
            if (resp?.status === 200) {
                const respData = resp?.data;
                setManagerData(respData);
            }
        })
        makeApiRequest(`/api/get-reportees-salary?managerId=${user?.id}`).then(resp => {
            if (resp?.status === 200) {
                const respData = resp?.data;
                setReporteesData(respData?.results);
            }
        })
    }, [user?.id])

    useEffect(() => {
        if (!user?.id) return;
        const filters = {};

        if (existingParams?.region) {
            filters.region = { $eq: existingParams.region };
        }

        if (existingParams?.department) {
            filters.department = { $eq: existingParams.department };
        }

        makeApiRequest(`/api/get-reportees-salary?managerId=${user?.id}`, 'GET', null, { filters })
            .then(resp => {
                if (resp?.status === 200) {
                    const respData = resp?.data;
                    setReporteesData(respData?.results);
                }
            }).catch(err => {
                console.error('Error fetching reportees salary data:', err);
            });
        /*
            axios.get(`/api/get-reportees-salary?managerId=${user?.id}`, {
                params: {
                    filters,
                }
            }).then(resp => {
                if (resp?.status === 200) {
                    const respData = resp?.data;
                    setReporteesData(respData?.results);
                }
            });
        */
    }, [existingParams?.department, existingParams?.region])


    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilters(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth="xl">
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 2,
                            flexWrap: 'wrap',
                        }}
                    >
                        <Box component="div" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            <StackedLineChartOutlinedIcon />
                            <Typography color="inherit" variant="h6" sx={{ ml: 1 }}>
                                Salary
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2}>
                        {userRole !== 'Employee' && <Grid item xs={12} lg={12}>
                            <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                                <FormControl variant='filled' sx={{ m: 1, minWidth: 125 }}>
                                    <InputLabel>Department</InputLabel>
                                    <Select
                                        value={department}
                                        onChange={(event) => {
                                            setDepartment(event.target.value);
                                            updateQueryParams({ department: event.target.value })
                                        }}
                                        size='small'
                                        label="Department"
                                    >
                                        <MenuItem value="">
                                            <em>All</em>
                                        </MenuItem>
                                        {departmentList.map((dItem, idx) => (
                                            <MenuItem key={`dep_${idx}`} value={dItem}>{dItem}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant='filled' sx={{ m: 1, minWidth: 100 }}>
                                    <InputLabel>Region</InputLabel>
                                    <Select
                                        value={region}
                                        onChange={(event) => {
                                            setRegion(event.target.value);
                                            updateQueryParams({ region: event.target.value })
                                        }}
                                        size='small'
                                        label="Region"
                                    >
                                        <MenuItem value="">
                                            <em>All</em>
                                        </MenuItem>
                                        {regionList.map((rItem, idx) => (
                                            <MenuItem key={`reg_${idx}`} value={rItem}>{rItem}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>}
                        <Grid item xs={12} lg={12}>
                            <HierarchyTable managerData={managerData} reporteesData={reporteesData} user={user} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default SalaryNavigation;